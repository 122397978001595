import {
  AccidentLevel,
  BenefitType,
  DeductionType,
  DisabilityLevel,
  HealthLevel,
  LifeLevel,
  PersonalAdvicePaymentMethod,
  SicknessAccidentLevel,
} from '@frontend/benefit-types';
import { FlexBenefitPackageAction } from 'app/apollo/graphql/types';
import { Tag } from 'features/benefit-portal/BenefitCard';
import {
  PensionPremiumCalculationMethod,
  PersonalAdviceMeetingFrequencyOptionValue,
} from 'features/benefits/utils/types';
import { defineMessages } from 'react-intl';

export const benefitTagMessages = defineMessages<Tag>({
  NEW: {
    id: 'benefitTagMessages.NEW',
    defaultMessage: 'Nyhet',
  },
});

export const commonBenefitMessages = defineMessages({
  insuranceBenefits: {
    id: 'commonBenefitMessages.insuranceBenefits',
    defaultMessage: 'Försäkringsförmåner',
  },
  benefitPortal: {
    id: 'commonBenefitMessages.benefitPortal',
    description: 'Förmånsportal',
    defaultMessage: 'Förmånsportal',
  },
  accident: {
    id: 'commonBenefitMessages.accidentInsurance',
    description: 'Olycksfallsförsäkring',
    defaultMessage: 'Olycksfallsförsäkring',
  },
  accidentShort: {
    id: 'commonBenefitMessages.accidentShort',
    description: 'Accident (short)',
    defaultMessage: 'Olycka',
  },
  pension: {
    id: 'commonBenefitMessages.pension',
    description: 'Ålderspension',
    defaultMessage: 'Ålderspension',
  },
  digitalAdvice: {
    id: 'commonBenefitMessages.digitalAdvice',
    defaultMessage: 'Digital rådgivning',
  },
  occupationalPension: {
    id: 'commonBenefitMessages.occupationalPension',
    description: 'Tjänstepension',
    defaultMessage: 'Tjänstepension',
  },
  salaryExchange: {
    id: 'commonBenefitMessages.salaryExchange',
    description: 'Löneväxling',
    defaultMessage: 'Löneväxling',
  },
  health: {
    id: 'commonBenefitMessages.health',
    description: 'Sjukvårdsförsäkring (PrivateAccess Silver)',
    defaultMessage: 'Sjukvårdsförsäkring',
  },
  healthShort: {
    id: 'commonBenefitMessages.healthShort',
    description: 'Health (short)',
    defaultMessage: 'Sjukvård',
  },
  sickness: {
    id: 'commonBenefitMessages.sickness',
    description: 'Sjukförsäkring (PlanSjuk)',
    defaultMessage: 'Sjukförsäkring',
  },
  sicknessShort: {
    id: 'commonBenefitMessages.sicknessShort',
    description: 'Sickness (short)',
    defaultMessage: 'Sjuk',
  },
  sicknessTitle: {
    id: 'commonBenefitMessages.sicknessTitle',
    description: 'Sickness (title)',
    defaultMessage: 'Sjukersättning',
  },
  tgl: {
    id: 'commonBenefitMessages.tgl',
    description: 'TGL-försäkring',
    defaultMessage: 'Tjänstegrupplivförsäkring',
  },
  insuranceCoverage: {
    id: 'commonBenefitMessages.insuranceCoverage',
    description: 'Insurance coverage',
    defaultMessage: 'Försäkringsskydd',
  },
  shortfall: {
    id: 'commonBenefitMessages.shortfall',
    description: 'Shortfall',
    defaultMessage: 'Underskott',
  },
  life: {
    id: 'commonBenefitMessages.lifeInsurance',
    description: 'Extra livförsäkring',
    defaultMessage: 'Livförsäkring',
  },
  lifeShort: {
    id: 'commonBenefitMessages.lifeShort',
    description: 'Life insurance (short)',
    defaultMessage: 'Liv',
  },
  noBenefits: {
    id: 'commonBenefitMessages.noBenefits',
    description: 'No benefits title',
    defaultMessage: 'Det finns inga förmåner att visa',
  },
  noBenefitsText: {
    id: 'commonBenefitMessages.noBenefitsText',
    description: 'No benefits in backage text',
    defaultMessage: 'Förmåner som läggs till kommer att visas här.',
  },
  noBenefitsInBackage: {
    id: 'commonBenefitMessages.noBenefitsInBackage',
    description: 'No benefits in backage title',
    defaultMessage: 'Det finns inga förmåner i denna förmånsgrupp',
  },
  noBenefitsInBackageText: {
    id: 'commonBenefitMessages.noBenefitsInBackageText',
    description: 'No benefits in backage text',
    defaultMessage:
      'Förmåner som läggs till i förmånsgruppen kommer att visas här.',
  },
  noBenefitPackagesTitle: {
    id: 'commonBenefitMessages.noBenefitPackagesTitle',
    description: 'There is no benefit packages',
    defaultMessage: 'Det finns inga förmånsgrupper att visa',
  },
  noBenefitPackagesText: {
    id: 'commonBenefitMessages.noBenefitPackagesText',
    description: 'There is no benefit packages text',
    defaultMessage: 'Förmånsgrupper som skapas kommer att visas här.',
  },
  levelLabel: {
    id: 'commonBenefitMessages.levelLabel',
    description: 'Välj mellan följande nivåer:',
    defaultMessage: 'Välj nivå',
  },
  benefitPackagesFieldLabel: {
    id: 'commonBenefitMessages.benefitPackagesFieldLabel',
    description: 'Vilka grupper skulle du vilja tilldela denna förmån till?',
    defaultMessage: 'Välj förmånsgrupper',
  },
  addBenefitsToBenefitPackage: {
    id: 'commonBenefitMessages.addBenefitToBenefitPackage',
    description: 'Add benefits to benefit package title',
    defaultMessage: 'Lägg till förmåner i "{name}"',
  },
  confirmAddToBackageTitle: {
    id: 'commonBenefitMessages.confirmAddToBackageTitle',
    defaultMessage: 'Lägg till förmåner i förmånsgruppen?',
  },
  confirmAddToBackage: {
    id: 'commonBenefitMessages.confirmAddToBackage',
    defaultMessage:
      'Valda förmåner kommer att läggas till i förmånsgruppen och börjar gälla direkt.',
  },
  parentalLeave: {
    id: 'commonBenefitMessages.parentalLeave',
    defaultMessage: 'Gäller vid föräldraledighet',
  },
  remunerationType: {
    id: 'commonBenefitMessages.remunerationType',
    defaultMessage: 'Löneart',
  },
  annualFactor: {
    id: 'commonBenefitMessages.annualFactor',
    defaultMessage: 'Årslönefaktor',
  },
  benefitQualifyingIncome: {
    id: 'commonBenefitMessages.benefitQualifyingIncome',
    defaultMessage: 'Förmånsgrundande löneart',
  },
  benefitQualifyingIncomeDescription: {
    id: 'commonBenefitMessages.benefitQualifyingIncomeDescription',
    defaultMessage:
      'Välj vilken löneart som ska vara förmånsgrundande för denna förmån. Lönearten måste räknas om till en årslön. För att ta hänsyn till semestertillägg på samma sätt som ITP 2, använd 12,2 som årslönefaktor. Om den förmånsgrundande lönearten redan är en årslön, använd 1,00 som årslönefaktor.',
  },
  benefitQualifyingIncomeTableDescription: {
    id: 'commonBenefitMessages.benefitQualifyingIncomeTableDescription',
    defaultMessage: `Förmånsgrundande löneart: {annualFactor, number} ∙ {remunerationType}`,
  },
  numberOfEmployees: {
    id: 'commonBenefitMessages.numberOfEmployees',
    defaultMessage: 'Antal anställda',
  },
  searchCategory: {
    id: 'commonBenefitMessages.searchCategory',
    defaultMessage: 'Sök kategori',
  },
  confirmLanguageSwitchTitle: {
    id: 'commonBenefitMessages.confirmLanguageSwitchTitle',
    defaultMessage: 'Byta språk?',
  },
  confirmLanguageSwitch: {
    id: 'commonBenefitMessages.confirmLanguageSwitch',
    defaultMessage:
      'Du har osparade ändringar som kommer att förloras om du byter språk.',
  },
  changeCoverImage: {
    id: 'commonBenefitMessages.changeCoverImage',
    defaultMessage: 'Ändra omslagsbild',
  },
  coverImagePlaceholder: {
    id: 'commonBenefitMessages.coverImagePlaceholder',
    defaultMessage: 'Dra och släpp en bild i formatet .jpg eller .png',
  },
  inBenefitPackage: {
    id: 'commonBenefitMessages.inBenefitPackage',
    defaultMessage: 'I förmånsgrupp',
  },
  extendedSickPayLabel: {
    id: 'formSubmitMessages.extendedSickPayLabel',
    defaultMessage: 'Medarbetare får utökad sjuklön enligt ITP',
  },
  insuranceProvider: {
    id: 'commonBenefitMessages.insuranceProvider',
    defaultMessage: 'Valbara försäkringsleverantörer',
  },
  level: {
    id: 'commonBenefitMessages.level',
    defaultMessage: 'Nivå',
  },
  versionHistory: {
    id: 'commonBenefitMessages.versionHistory',
    defaultMessage: 'Versionshistorik',
  },
  benefitsPreamble: {
    id: 'commonBenefitMessages.benefitsPreamble',
    defaultMessage:
      'För att skapa en ny förmån eller göra ändringar i en befintlig förmån, vänligen kontakta kundansvarig eller kundtjänst.',
  },
  benefitPackagesPreamble: {
    id: 'commonBenefitMessages.benefitPackagesPreamble',
    defaultMessage:
      'För att skapa en ny förmånsgrupp eller göra ändringar i en befintlig förmånsgrupp, vänligen kontakta kundansvarig eller kundtjänst.',
  },
  noHistory: {
    id: 'commonBenefitMessages.noHistory',
    defaultMessage: 'Det finns ingen historik att visa',
  },
  benefitPackageAuthorInfo: {
    id: 'commonBenefitMessages.benefitPackageAuthorInfo',
    defaultMessage: 'Förmånsgrupp skapad { createdAt } av { author }',
  },
  agreementNumber: {
    id: 'commonBenefitMessages.agreementNumber',
    defaultMessage: 'Avtalsnummer',
  },
});

export const personalAdviceFeeDetailsMessages = defineMessages<
  Exclude<PersonalAdvicePaymentMethod, 'CAPITAL_FEE'>
>({
  EMPLOYER_PAYS_PER_MEETING: {
    id: 'benefitFormMessages.EMPLOYER_PAYS_PER_MEETING',
    defaultMessage: 'Avgift: {fee, number, ::currency/SEK .}',
  },
  EMPLOYER_PAYS_SUBSCRIPTION: {
    id: 'benefitFormMessages.EMPLOYER_PAYS_SUBSCRIPTION',
    defaultMessage: 'Avgift: {fee, number, ::currency/SEK .}/mån',
  },
  EMPLOYEE_PAYS_PREMIUM_DEDUCTION: {
    id: 'benefitFormMessages.EMPLOYEE_PAYS_PREMIUM_DEDUCTION',
    defaultMessage: 'Avgift: {fee, number, ::currency/SEK .}/mån',
  },
  EMPLOYEE_PAYS_PER_MEETING: {
    id: 'benefitFormMessages.EMPLOYEE_PAYS_PER_MEETING',
    defaultMessage: 'Avgift: {fee, number, ::currency/SEK .}',
  },
});

export const accidentBenefitMessages = defineMessages<AccidentLevel>({
  PBB20: {
    id: 'accidentBenefitMessages.PBB20',
    defaultMessage: '20 Prisbasbelopp',
  },
  PBB30: {
    id: 'accidentBenefitMessages.PBB30',
    defaultMessage: '30 Prisbasbelopp',
  },
  PBB40: {
    id: 'accidentBenefitMessages.PBB40',
    defaultMessage: '40 Prisbasbelopp',
  },
  PBB50: {
    id: 'accidentBenefitMessages.PBB50',
    defaultMessage: '50 Prisbasbelopp',
  },
});

export const healthBenefitMessages = defineMessages({
  healthLevel: {
    id: 'healthBenefitMessages.healthLevel',
    description: 'Deductible',
    defaultMessage: 'Självrisk',
  },
  levelDescription: {
    id: 'healthBenefitMessages.levelDescription',
    defaultMessage:
      'Premien varierar beroende på nivå av självrisk. Kontakta oss för offert',
  },
  levelOptionLabel: {
    id: 'healthBenefitMessages.levelOptionLabel',
    description: '{value} deductible',
    defaultMessage: '{value} självrisk',
  },
  levelOptionZeroLabel: {
    id: 'healthBenefitMessages.levelOptionZeroLabel',
    description: 'Without deductible',
    defaultMessage: 'Utan självrisk',
  },
});

export const pensionBenefitPremiumCalculationMethodMessages =
  defineMessages<PensionPremiumCalculationMethod>({
    ITP_MATRIX: {
      id: 'pensionBenefitPremiumTypeMessages.ITP_MATRIX',
      defaultMessage: 'ITP-trappa',
    },
    ITP_LIKE_MATRIX: {
      id: 'pensionBenefitPremiumTypeMessages.ITP_LIKE_MATRIX',
      defaultMessage: 'ITP-liknande trappa',
    },
    CUSTOM_MATRIX: {
      id: 'pensionBenefitPremiumTypeMessages.CUSTOM_MATRIX',
      defaultMessage: 'Egen premietrappa',
    },
    FIXED_PREMIUM_ONLY: {
      id: 'pensionBenefitPremiumTypeMessages.FIXED_PREMIUM_ONLY',
      defaultMessage: 'Fast individuell premie',
    },
  });

export const pensionBenefitTypeMessages = defineMessages({
  INDIVIDUAL: {
    id: 'pensionBenefitTypeMessages.INDIVIDUAL',
    defaultMessage: 'Individuell avsättning',
  },
  ITP: {
    id: 'pensionBenefitTypeMessages.ITP',
    defaultMessage: 'ITP-trappa',
  },
  PREMIUM_MATRIX: {
    id: 'pensionBenefitTypeMessages.PREMIUM_MATRIX',
    defaultMessage: 'Egen premietrappa',
  },
});

export const pensionBenefitMessages = defineMessages({
  pensionProviders: {
    id: 'pensionBenefitMessages.pensionProviders',
    defaultMessage: `{count, plural,
      =1 {Leverantör}
      other {Leverantörer}}`,
  },
  retirementAge: {
    id: 'pensionBenefitMessages.retirementAge',
    defaultMessage: 'Pensionsålder',
  },
  premiumWaiver: {
    id: 'pensionBenefitMessages.premiumWaiver',
    defaultMessage: 'Premiebefrielseförsäkring',
  },
  deductionsTableMessage: {
    id: 'pensionBenefitMessages.deductionsTableMessage',
    defaultMessage: 'Avdrag inom premieramen: {deductions}',
  },
  fixedExtraPremium: {
    id: 'pensionBenefitMessages.fixedExtraPremium',
    defaultMessage: 'Fast extra premie',
  },
  premiumCalculationMethod: {
    id: 'pensionBenefitMessages.premiumCalculationMethod',
    defaultMessage: 'Premieberäkningsmetod',
  },
  premiumDeductions: {
    id: 'pensionBenefitMessages.premiumDeductions',
    defaultMessage: 'Avdrag inom premieutrymmet',
  },
  fixedPremium: {
    id: 'pensionBenefitMessages.fixedPremium',
    defaultMessage: 'Fast extra premie utöver premietrappa',
  },
});

export const pensionBenefitProviderMessages = defineMessages({
  advinans: {
    id: 'pensionBenefitProviderMessages.advinans',
    defaultMessage: 'Advinans Autopilot',
  },
  nordnet: {
    id: 'pensionBenefitProviderMessages.nordnet',
    defaultMessage: 'Nordnet',
  },
  nordnet_ssek: {
    id: 'pensionBenefitProviderMessages.nordnet_ssek',
    defaultMessage: 'Nordnet',
  },
  lf: {
    id: 'pensionBenefitProviderMessages.lf',
    defaultMessage: 'Länsförsäkringar',
  },
  nordea_fund: {
    id: 'pensionBenefitProviderMessages.nordea_fund',
    defaultMessage: 'Nordea Fondförsäkring',
  },
  nordea_trad: {
    id: 'pensionBenefitProviderMessages.nordea_trad',
    defaultMessage: 'Nordea Traditionell försäkring',
  },
});

export const pensionBenefitDeducionMessages = defineMessages<DeductionType>({
  ADVINANS_MEMBERSHIP_FEE: {
    id: 'pensionBenefitDeductionMessages.ADVINANS_MEMBERSHIP_FEE',
    defaultMessage: 'Nordea Nodes plattformsavgift',
  },
  ADVINANS_ADVICE_FEE: {
    id: 'pensionBenefitDeductionMessages.ADVINANS_ADVICE_FEE',
    defaultMessage: 'Personlig rådgivning',
  },
  PREMIUM_WAIVER: {
    id: 'pensionBenefitDeductionMessages.PREMIUM_WAIVER',
    defaultMessage: 'Premiebefrielseförsäkring',
  },
  SICKNESS_INSURANCE: {
    id: 'pensionBenefitDeductionMessages.SICKNESS_INSURANCE',
    defaultMessage: 'Sjukförsäkring',
  },
});

export const sicknessBenefitMessages = defineMessages({
  ITP: {
    id: 'sicknessBenefitMessages.ITP',
    description: 'ITP-Sjuk',
    defaultMessage: 'ITP-sjuk',
  },
  MAX: {
    id: 'sicknessBenefitMessages.MAX',
    description: 'Max-Sjuk',
    defaultMessage: 'MAX-sjuk',
  },
  KOMP: {
    id: 'sicknessBenefitMessages.KOMP',
    defaultMessage: 'ITP-komplettering',
  },
  HYBRID: {
    id: 'sicknessBenefitMessages.HYBRID',
    defaultMessage: 'Hybrid-sjuk (ITP/MAX)',
  },
  fromSicknessInsurance: {
    id: 'sicknessBenefitMessages.fromSicknessInsurance',
    description: 'From sickness insurance',
    defaultMessage: 'Från sjukförsäkringen',
  },
  fromEmployer: {
    id: 'sicknessBenefitMessages.fromEmployer',
    description: 'From employer',
    defaultMessage: 'Från arbetsgivare',
  },
  lossOfIncome: {
    id: 'sicknessBenefitMessages.lossOfIncome',
    description: 'Inkomstbortfall',
    defaultMessage: 'Inkomstbortfall',
  },
  fromSocialInsuranceAgency: {
    id: 'sicknessBenefitMessages.fromSocialInsuranceAgency',
    description: 'Från Försäkringskassan',
    defaultMessage: 'Från Försäkringskassan',
  },
  withExtendedSickPay: {
    id: 'sicknessBenefitMessages.withExtendedSickPay',
    defaultMessage: 'med utökad sjuklön',
  },
});

export const sicknessBenefitLevelMessages = defineMessages<DisabilityLevel>({
  ITP: sicknessBenefitMessages.ITP,
  MAX: sicknessBenefitMessages.MAX,
  KOMP: sicknessBenefitMessages.KOMP,
  HYBRID: sicknessBenefitMessages.HYBRID,
  CONSOLIDATION: {
    id: 'sicknessBenefitLevelMessages.CONSOLIDATION',
    defaultMessage: 'Konsolidering',
  },
});

export const healthcareLevelMessages = defineMessages<HealthLevel>({
  BRONS_0: {
    id: 'smeHealthcareLevelMessages.BRONS_0',
    defaultMessage: 'Brons utan självrisk',
  },
  BRONS_500: {
    id: 'smeHealthcareLevelMessages.BRONS_500',
    defaultMessage: 'Brons 500 kr',
  },
  L0: {
    id: 'smeHealthcareLevelMessages.L0',
    defaultMessage: 'Silver utan självrisk',
  },
  L750: {
    id: 'smeHealthcareLevelMessages.L750',
    defaultMessage: 'Silver 750 kr',
  },
  L1000: {
    id: 'smeHealthcareLevelMessages.L1000',
    defaultMessage: 'Silver 1000 kr',
  },
  L1500: {
    id: 'smeHealthcareLevelMessages.L1500',
    defaultMessage: 'Silver 1500 kr',
  },
  GOLD: {
    id: 'smeHealthcareLevelMessages.GOLD',
    defaultMessage: 'Guld',
  },
});

export const wellnessBenefitMessages = defineMessages({
  viaEpassi: {
    id: 'wellnessBenefitMessages.viaEpassi',
    description: 'Via Epassi',
    defaultMessage: 'Via Epassi',
  },
  wellness: {
    id: 'wellnessBenefitMessages.wellness',
    description: 'Wellness',
    defaultMessage: 'Friskvård',
  },
  annualAllowance: {
    id: 'wellnessBenefitMessages.annualAllowance',
    description: 'Annual allowance',
    defaultMessage: 'Årligt friskvårdsbidrag',
  },
  customerId: {
    id: 'wellnessBenefitMessages.customerId',
    defaultMessage: 'Kund-ID',
  },
  epassiBikeStandardPolicy: {
    id: 'wellnessBenefitMessages.epassiBikeStandardPolicy',
    defaultMessage: 'Epassis standardpolicy',
  },
  epassiBikeCustomCompanyPolicy: {
    id: 'wellnessBenefitMessages.epassiBikeCustomCompanyPolicy',
    defaultMessage: 'Egen policy',
  },
  epassiBikePolicy: {
    id: 'wellnessBenefitMessages.epassiBikePolicy',
    defaultMessage: 'Bifoga policy/villkor',
  },
});

export const editBenefitMessages = defineMessages({
  configuration: {
    id: 'editBenefitMessages.configuration',
    defaultMessage: 'Anpassa',
  },
  presentation: {
    id: 'editBenefitMessages.presentation',
    defaultMessage: 'Presentation',
  },
  successfulBenefitSave: {
    id: 'editBenefitMessages.successfulBenefitSave',
    defaultMessage: `Förmånen "{name}" sparades!`,
  },
  createDiscount: {
    id: 'editBenefitMessages.createDiscount',
    defaultMessage: 'Skapa erbjudande',
  },
  editBenefitPresentation: {
    id: 'editBenefitMessages.editBenefitPresentation',
    defaultMessage: 'Redigera presentation',
  },
  editTitle: {
    id: 'editBenefitMessages.editTitle',
    defaultMessage: 'Redigera titel',
  },
  title: {
    id: 'editBenefitMessages.title',
    defaultMessage: 'Titel',
  },
  short: {
    id: 'editBenefitMessages.short',
    defaultMessage: 'Underrubrik',
  },
  primaryCta: {
    id: 'editBenefitMessages.primaryCta',
    defaultMessage: 'Primär CTA',
  },
  secondaryCta: {
    id: 'editBenefitMessages.secondaryCta',
    defaultMessage: 'Sekundär CTA',
  },
  linkAddress: {
    id: 'editBenefitMessages.linkAddress',
    defaultMessage: 'Länkadress',
  },
  editLinks: {
    id: 'editBenefitMessages.editLinks',
    defaultMessage: 'Redigera länkar',
  },
  exampleLink: {
    id: 'editBenefitMessages.exampleLink',
    defaultMessage: 'Exempellänk',
  },
  discountCode: {
    id: 'editBenefitMessages.discountCode',
    defaultMessage: 'Rabattkod',
  },
  attachments: {
    id: 'editBenefitMessages.attachments',
    defaultMessage: 'Bifogad fil',
  },
  attachFile: {
    id: 'editBenefitMessages.attachFile',
    defaultMessage: 'Bifoga fil',
  },
  embeddEditorToolbarOption: {
    id: 'editBenefitMessages.embeddEditorToolbarOption',
    defaultMessage: 'Bädda in',
  },
  newCategory: {
    id: 'editBenefitMessages.newCategory',
    defaultMessage: 'Ny Kategori',
  },
  addCategory: {
    id: 'editBenefitMessages.addCategory',
    defaultMessage: 'Lägg till kategori',
  },
  categoryNameEnglish: {
    id: 'editBenefitMessages.categoryNameEnglish',
    defaultMessage: 'Namn på kategori - Engelska',
  },
  categoryNameSwedish: {
    id: 'editBenefitMessages.categoryNameSwedish',
    defaultMessage: 'Namn på kategori - Svenska',
  },
  alignCoverImageTop: {
    id: 'editBenefitMessages.alignCoverImageTop',
    defaultMessage: 'Justera omslagsbild upptill',
  },
  alignCoverImageCenter: {
    id: 'editBenefitMessages.alignCoverImageCenter',
    defaultMessage: 'Justera omslagsbild i mitten',
  },
  alignCoverImageBottom: {
    id: 'editBenefitMessages.alignCoverImageBottom',
    defaultMessage: 'Justera omslagsbild nedtill',
  },
});

export const benefitFilterMessages = defineMessages({
  chooseCategory: {
    id: 'benefitFilterMessages.chooseCategory',
    defaultMessage: 'Välj kategori',
  },
  chooseType: {
    id: 'benefitFilterMessages.chooseType',
    defaultMessage: 'Välj förmån',
  },
});

export const digitalAdviceMessages = defineMessages({
  pensionAdviceTitle: {
    id: 'digitalAdviceMessages.pensionAdviceTitle',
    defaultMessage: 'Pensionsrådgivning',
  },
  insuranceAdviceTitle: {
    id: 'digitalAdviceMessages.insuranceAdviceTitle',
    defaultMessage: 'Försäkringsrådgivning',
  },
  digitalAdviceDesc: {
    id: 'digitalAdviceMessages.digitalAdviceDesc',
    defaultMessage: 'Dina senaste råd genererades',
  },
  noPreviousAdviceTitle: {
    id: 'digitalAdviceMessages.noPreviousAdviceTitle',
    defaultMessage: 'Ingen tidigare rådgivning',
  },
  pensionAdviceLink: {
    id: 'digitalAdviceMessages.pensionAdviceLink',
    defaultMessage: 'Till Pensionsrådgivning',
  },
  insuranceAdviceLink: {
    id: 'digitalAdviceMessages.insuranceAdviceLink',
    defaultMessage: 'Till Försäkringsrådgivning',
  },
  activatePlusLink: {
    id: 'digitalAdviceMessages.activatePlusLink',
    defaultMessage: 'Aktivera',
  },
});

export const benefitPackageActionMessages =
  defineMessages<FlexBenefitPackageAction>({
    ADDED: {
      id: 'benefitPackageActionMessages.ADDED',
      defaultMessage: 'Förmån tillagd',
    },
    REMOVED: {
      id: 'benefitPackageActionMessages.REMOVED',
      defaultMessage: 'Förmån borttagen',
    },
  });
export const personalAdviceMessages = defineMessages({
  paymentMethod: {
    id: 'personalAdviceMessages.paymentMethod',
    defaultMessage: 'Betalningsmetod',
  },
  freeAdviceAtLaunch: {
    id: 'personalAdviceMessages.freeAdviceAtLaunch',
    defaultMessage: 'Gratis möte vid lansering',
  },
  onboadingFreePeriod: {
    id: 'personalAdviceMessages.onboadingFreePeriod',
    defaultMessage:
      'Medarbetare har möjlighet att boka möte utan kostnad under 6 månader från lansering',
  },
  onboardingFreePeriodStartDate: {
    id: 'personalAdviceMessages.onboardingFreePeriodStartDate',
    defaultMessage: 'Lanseringsdatum',
  },
  employmentStartFreePeriod: {
    id: 'benefitFormMessages.employmentStartFreePeriod',
    defaultMessage:
      'Nya medarbetare har möjlighet att boka möte utan kostnad under 6 månader från anställningsstart',
  },
  meetingFrequencyMonths: {
    id: 'benefitFormMessages.meetingFrequencyMonthsTitle',
    defaultMessage: 'Mötesfrekvens',
  },
});

export const personalAdvicePaymentMethodMessages =
  defineMessages<PersonalAdvicePaymentMethod>({
    EMPLOYER_PAYS_PER_MEETING: {
      id: 'personalAdvicePaymentMethodMessages.EMPLOYER_PAYS_PER_MEETING',
      defaultMessage: 'Betalas av arbetsgivaren - Avgift per möte',
    },
    EMPLOYER_PAYS_SUBSCRIPTION: {
      id: 'personalAdvicePaymentMethodMessages.EMPLOYER_PAYS_SUBSCRIPTION',
      defaultMessage: 'Betalas av  arbetsgivaren - Månadsabonnemang',
    },
    EMPLOYEE_PAYS_PREMIUM_DEDUCTION: {
      id: 'personalAdvicePaymentMethodMessages.EMPLOYEE_PAYS_PREMIUM_DEDUCTION',
      defaultMessage:
        'Betalas av medarbetare - Premieavdrag för tjänstepension',
    },
    EMPLOYEE_PAYS_PER_MEETING: {
      id: 'personalAdvicePaymentMethodMessages.EMPLOYEE_PAYS_PER_MEETING',
      defaultMessage: 'Betalas av medarbetare - Avgift per möte',
    },
    CAPITAL_FEE: {
      id: 'personalAdvicePaymentMethodMessages.CAPITAL_FEE',
      defaultMessage: 'Rådgivning ingår - Utan extra kostnad',
    },
  });

export const personalAdviceMeetingFrequencyMessages =
  defineMessages<PersonalAdviceMeetingFrequencyOptionValue>({
    ONE_ADVICE_MEETING_LIMIT: {
      id: 'personalAdviceMeetingFrequencyMessages.ONE_ADVICE_MEETING_LIMIT',
      defaultMessage: 'Begränsa till ett möte',
    },
    '0': {
      id: 'personalAdviceMeetingFrequencyMessages._0',
      defaultMessage: 'Obegränsat',
    },
    '12': {
      id: 'personalAdviceMeetingFrequencyMessages._12',
      defaultMessage: '12 månader',
    },
    '24': {
      id: 'personalAdviceMeetingFrequencyMessages._24',
      defaultMessage: '24 månader',
    },
    '36': {
      id: 'personalAdviceMeetingFrequencyMessages._36',
      defaultMessage: '36 månader',
    },
    '48': {
      id: 'personalAdviceMeetingFrequencyMessages._48',
      defaultMessage: '48 månader',
    },
    '60': {
      id: 'personalAdviceMeetingFrequencyMessages._60',
      defaultMessage: '60 månader',
    },
  });

export const benefitSicknessAndAccidentLevelMessages =
  defineMessages<SicknessAccidentLevel>({
    PBB20: {
      id: 'benefitSicknessAndAccidentLevelMessages.PBB20',
      defaultMessage: '20 Prisbasbelopp',
    },
    PBB30: {
      id: 'benefitSicknessAndAccidentLevelMessages.PBB30',
      defaultMessage: '30 Prisbasbelopp',
    },
    PBB40: {
      id: 'benefitSicknessAndAccidentLevelMessages.PBB40',
      defaultMessage: '40 Prisbasbelopp',
    },
    PBB50: {
      id: 'benefitSicknessAndAccidentLevelMessages.PBB50',
      defaultMessage: '50 Prisbasbelopp',
    },
  });

export const benefitLifeLevelMessages = defineMessages<LifeLevel>({
  PBB5: {
    id: 'benefitLifeLevelMessages.PBB5',
    defaultMessage: '5 Prisbasbelopp',
  },
  PBB10: {
    id: 'benefitLifeLevelMessages.PBB10',
    defaultMessage: '10 Prisbasbelopp',
  },
  PBB15: {
    id: 'benefitLifeLevelMessages.PBB15',
    defaultMessage: '15 Prisbasbelopp',
  },
  PBB20: {
    id: 'benefitLifeLevelMessages.PBB20',
    defaultMessage: '20 Prisbasbelopp',
  },
  PBB25: {
    id: 'benefitLifeLevelMessages.PBB25',
    defaultMessage: '25 Prisbasbelopp',
  },
  PBB30: {
    id: 'benefitLifeLevelMessages.PBB30',
    defaultMessage: '30 Prisbasbelopp',
  },
  PBB35: {
    id: 'benefitLifeLevelMessages.PBB35',
    defaultMessage: '35 Prisbasbelopp',
  },
  PBB40: {
    id: 'benefitLifeLevelMessages.PBB40',
    defaultMessage: '40 Prisbasbelopp',
  },
  PBB45: {
    id: 'benefitLifeLevelMessages.PBB45',
    defaultMessage: '45 Prisbasbelopp',
  },
  PBB50: {
    id: 'benefitLifeLevelMessages.PBB50',
    defaultMessage: '50 Prisbasbelopp',
  },
});

export const benefitTypesMessages = defineMessages<BenefitType>({
  advinans_occupational_pension: {
    id: 'benefitTypesMessages.advinans_occupational_pension',
    defaultMessage: 'Tjänstepension',
  },
  advinans_salary_exchange: {
    id: 'benefitTypesMessages.advinans_salary_exchange',
    defaultMessage: 'Löneväxling',
  },
  employers_pension: {
    id: 'benefitTypesMessages.employers_pension',
    defaultMessage: 'Företagarpension',
  },
  epassi: {
    id: 'benefitTypesMessages.epassi',
    defaultMessage: 'Epassi friskvård',
  },
  epassi_bike: {
    id: 'benefitTypesMessages.epassi_bike',
    defaultMessage: 'EpassiBIKE cykelförmån',
  },
  euro_accident_accident: {
    id: 'benefitTypesMessages.euro_accident_accident',
    defaultMessage: 'Olycksfallsförsäkring',
  },
  euro_accident_life: {
    id: 'benefitTypesMessages.euro_accident_life',
    defaultMessage: 'Livförsäkring (obligatorisk)',
  },
  euro_accident_plansjuk: {
    id: 'benefitTypesMessages.euro_accident_plansjuk',
    defaultMessage: 'Sjukförsäkring',
  },
  euro_accident_privataccess: {
    id: 'benefitTypesMessages.euro_accident_privataccess',
    defaultMessage: 'Sjukvårdsförsäkring',
  },
  euro_accident_sickness_and_accident: {
    id: 'benefitTypesMessages.euro_accident_sickness_and_accident',
    defaultMessage: 'Sjuk- och olycksfallsförsäkring',
  },
  euro_accident_tgl: {
    id: 'benefitTypesMessages.euro_accident_tgl',
    defaultMessage: 'Tjänstegrupplivförsäkring',
  },
  nordea_node_digital_advice: {
    id: 'benefitTypesMessages.nordea_node_digital_advice',
    defaultMessage: 'Digital rådgivning',
  },
  nordea_node_personal_advice: {
    id: 'benefitTypesMessages.nordea_node_personal_advice',
    defaultMessage: 'Personlig rådgivning',
  },
  synsam: {
    id: 'benefitTypesMessages.synsam',
    defaultMessage: 'Synsam terminalglasögon',
  },
  wellness: {
    id: 'benefitTypesMessages.wellness',
    defaultMessage: 'Friskvård',
  },
});
